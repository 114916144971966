import type {
  CurrentUser,
  CurrentCustomer,
  CurrentCustomerAccessPermission,
  SubscriptionStatus
} from '~/modules/auth/types/session'
import { SignupStatus } from '~/modules/signup/types/signup-status'
import { daysUntilADate } from '~/utils/datetime'

export interface ContentProps {
  isFreebie?: boolean
}

export type ContentError = {
  code: ContentErrorType
  message: string
}

export enum ContentErrorType {
  SignupUpRequired = 'signup-required',
  CustomerMissing = 'customer-missing',
  AccountNotActivated = 'account-not-activated',
  SubscriptionMissing = 'subscription-missing',
  SubscriptionLapsed = 'subscription-lapsed',
  FreeAccessExpired = 'free-access-expired',
  NotAvailable = 'not-available',
  InsufficientPermissions = 'insufficient-permissions'
}

export const checkAccessPermissions = (
  currentUser?: CurrentUser | null,
  currentCustomer?: CurrentCustomer | null,
  currentCustomerAccessPermission?: CurrentCustomerAccessPermission | null,
  contentProps: ContentProps = {}
): ContentError | null => {
  const { hasFreeAccess, validTo, subscriptionStatus, signupStatus } = currentCustomer || {}
  const { canViewContent, isCustomerAdmin } = currentCustomerAccessPermission || {}
  const { isFreebie } = contentProps || {}

  // User not logged in
  if (!currentUser) {
    // Not a freebie so no access
    if (!isFreebie) {
      return { code: ContentErrorType.SignupUpRequired, message: 'You need to signup to access this content' }
    }

    // Is a free printable/video, so they can access it
    return null
  }

  // User is logged in

  if (!currentCustomer || !currentCustomerAccessPermission) {
    // Not a freebie so no access
    if (!isFreebie) {
      return { code: ContentErrorType.CustomerMissing, message: 'You need a paid account to access this content' }
    }

    // Is a free printable/video, so they can access it
    return null
  }

  const validDate = validTo ? new Date(validTo) : undefined
  const isInFuture = validDate ? validDate.getTime() - Date.now() > 0 : undefined

  // If hasFreeAccess, and validTo is set, that means user has free access until a certain date
  if (hasFreeAccess) {
    if (validTo && !isInFuture) {
      return {
        code: ContentErrorType.FreeAccessExpired,
        message: 'You need an active subscription to view this content'
      }
    }
    // If hasFreeAccess, but validTo is not set, that means user has free access indefinitely
    return null
  }

  if (!subscriptionStatus) {
    switch (signupStatus) {
      case SignupStatus.DETAILS_ENTERED:
        return {
          code: ContentErrorType.AccountNotActivated,
          message: 'You must activate your account to view this content'
        }

      default:
        return {
          code: ContentErrorType.SubscriptionMissing,
          message: 'You need an active subscription to view this content'
        }
    }
  }

  const validSubscription = checkIfActive(subscriptionStatus)

  if (!validSubscription) {
    return {
      code: ContentErrorType.SubscriptionLapsed,
      message: 'You need an active subscription to view this content'
    }
  }

  if (!canViewContent) {
    return {
      code: ContentErrorType.InsufficientPermissions,
      message: isCustomerAdmin
        ? 'Admin-only users cannot access content'
        : 'You do not have permission to view this content'
    }
  }

  return null
}

export const isSubscriptionInactive = (subscriptionStatus?: SubscriptionStatus | null) => {
  return !subscriptionStatus || checkIfInactive(subscriptionStatus)
}

export interface CanStartNewSubscriptionProps {
  hasFreeAccess?: boolean
  subscriptionStatus?: SubscriptionStatus | undefined | null
  validTo?: string | null | undefined
}

export const canStartNewSubscription = ({
  hasFreeAccess,
  subscriptionStatus,
  validTo
}: CanStartNewSubscriptionProps) => {
  return (
    checkIfInactive(subscriptionStatus) ||
    (!subscriptionStatus && !hasFreeAccess) ||
    (!subscriptionStatus && hasFreeAccess && validTo && daysUntilADate(validTo) <= 1)
  )
}

export const checkIfActive = (subscriptionStatus?: SubscriptionStatus | null) =>
  subscriptionStatus && ['active', 'trialing', 'past_due', 'incomplete', 'paused'].includes(subscriptionStatus)

export const checkIfInactive = (subscriptionStatus?: SubscriptionStatus | null) =>
  subscriptionStatus && ['canceled', 'incomplete_expired', 'unpaid'].includes(subscriptionStatus)
